import { CurrentHotel, useCurrentHotel } from '../../common/hooks/currentHotel';
import Link from 'next/link';
import CurrentHotelLink from '../../core/components/CurrentHotelLink';
import BasePageLayout from '../../common/components/BasePageLayout';
import { useQuery } from '../../common/hooks/apiClient';
import { useEffect, useMemo } from 'react';
import { Button, Link as ChakraLink } from '@chakra-ui/react';
import { useRouter } from 'next/router';

function ProvisionalPlanList() {
  const hotel = useCurrentHotel();
  const router = useRouter();

  useEffect(() => {
    if (hotel) {
      router.push('/' + hotel.slug + '/contrats');
    }
  }, [hotel]);

  const url = useMemo(
    () =>
      '/api/provisional-plans/?filters[hotel]=' + encodeURIComponent(hotel.id),
    [hotel.id]
  );

  const [loaded, error, provisionalPlans] = useQuery(url);

  if (error) {
    return <p>erreur de chargement</p>;
  }

  if (!loaded) {
    return <p>chargement liste</p>;
  }

  return (
    <div>
      <h2>Liste des plans prévisionnels :</h2>
      <ul>
        {provisionalPlans.items.map((provisionalPlan) => {
          return (
            <li key={provisionalPlan.id}>
              <CurrentHotelLink
                href={`/plan-previsionel/${provisionalPlan.id}`}
              >
                <ChakraLink>{provisionalPlan.name}</ChakraLink>
              </CurrentHotelLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ShowHotelIndex() {
  return (
    <>
      <ProvisionalPlanList />

      <div>
        <CurrentHotelLink href="/plan-previsionel/creer">
          <Link>
            <Button>Créer plan prévisionnel</Button>
          </Link>
        </CurrentHotelLink>
      </div>

      <div>
        <CurrentHotelLink href="/ligne-plan/ajouter">
          <Link>
            <Button>Ajouter une ligne de plan</Button>
          </Link>
        </CurrentHotelLink>
      </div>
    </>
  );
}

export default function HotelIndex(props) {
  return (
    <BasePageLayout>
      <CurrentHotel>
        <ShowHotelIndex />
      </CurrentHotel>
    </BasePageLayout>
  );
}
